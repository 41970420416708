// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-challenge-details-page-js": () => import("./../../../src/pages/ChallengeDetailsPage.js" /* webpackChunkName: "component---src-pages-challenge-details-page-js" */),
  "component---src-pages-challenges-page-js": () => import("./../../../src/pages/ChallengesPage.js" /* webpackChunkName: "component---src-pages-challenges-page-js" */),
  "component---src-pages-home-page-js": () => import("./../../../src/pages/HomePage.js" /* webpackChunkName: "component---src-pages-home-page-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-page-js": () => import("./../../../src/pages/LogoutPage.js" /* webpackChunkName: "component---src-pages-logout-page-js" */),
  "component---src-pages-not-available-page-js": () => import("./../../../src/pages/NotAvailablePage.js" /* webpackChunkName: "component---src-pages-not-available-page-js" */),
  "component---src-pages-settings-page-js": () => import("./../../../src/pages/SettingsPage.js" /* webpackChunkName: "component---src-pages-settings-page-js" */),
  "component---src-pages-statistics-page-js": () => import("./../../../src/pages/StatisticsPage.js" /* webpackChunkName: "component---src-pages-statistics-page-js" */),
  "component---src-pages-team-details-page-js": () => import("./../../../src/pages/TeamDetailsPage.js" /* webpackChunkName: "component---src-pages-team-details-page-js" */)
}

